import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Welcome from '../components/auth/Welcome';

const Home = () => {
  const { isAuthenticated, initialized, loading } = useAuth();

  // Show welcome page while loading or for non-authenticated users
  if (!initialized || loading || !isAuthenticated) {
    return <Welcome />;
  }

  // Only redirect after we're sure about authentication state
  return <Navigate to="/dashboard" replace />;
};

export default Home;
