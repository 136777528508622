import React, { useState, useEffect } from 'react';
import { useHousingSettings } from '../../hooks/useHousingSettings';

const HousingCalculator = () => {
  const { settings: savedSettings, loading: settingsLoading, error: settingsError } = useHousingSettings();
  
  const [annualIncome, setAnnualIncome] = useState(null);
  const [downPayment, setDownPayment] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [loanTerm, setLoanTerm] = useState(null);
  const [monthlyDebt, setMonthlyDebt] = useState(null);
  const [propertyTaxRate, setPropertyTaxRate] = useState(null);
  const [homeInsurance, setHomeInsurance] = useState(null);
  const [hoa, setHoa] = useState(null);
  
  const [inputStates, setInputStates] = useState({});
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);

  const calculateWithSettings = React.useCallback((settings) => {
    const {
      annualIncome,
      downPayment,
      interestRate,
      loanTerm,
      monthlyDebt,
      propertyTaxRate,
      homeInsurance,
      hoa
    } = settings;
    
    const monthlyIncome = annualIncome / 12;
    const maxMonthlyPayment = (monthlyIncome * 0.36) - monthlyDebt;
    
    const monthlyRate = interestRate / 100 / 12;
    const totalPayments = loanTerm * 12;
    
    const maxPrincipalAndInterest = maxMonthlyPayment - 
      ((propertyTaxRate / 100) / 12 * (downPayment + 100000)) -
      homeInsurance - 
      hoa;
    
    const numerator = Math.pow(1 + monthlyRate, totalPayments) - 1;
    const denominator = monthlyRate * Math.pow(1 + monthlyRate, totalPayments);
    const maxLoanAmount = maxPrincipalAndInterest * (numerator / denominator);
    
    const maxHomePrice = maxLoanAmount + downPayment;
    
    const monthlyMortgage = calculateMonthlyMortgage(maxHomePrice - downPayment, interestRate, loanTerm);
    const totalMonthlyPayment = monthlyMortgage + 
      ((propertyTaxRate / 100) / 12 * maxHomePrice) + 
      homeInsurance + 
      hoa;
    
    const newResult = {
      affordableHomePrice: Math.round(maxHomePrice),
      monthlyPayment: Math.round(totalMonthlyPayment),
      mortgagePayment: Math.round(monthlyMortgage),
      taxesAndInsurance: Math.round(((propertyTaxRate / 100) / 12 * maxHomePrice) + homeInsurance + hoa),
      debtToIncomeRatio: Math.round(((totalMonthlyPayment + monthlyDebt) / monthlyIncome) * 100) / 100,
      scenarios: [
        { 
          name: 'Conservative (28% rule)', 
          affordablePrice: Math.round(calculateAffordabilityByRule(0.28, annualIncome, monthlyDebt, downPayment, interestRate, loanTerm, propertyTaxRate, homeInsurance, hoa)) 
        },
        { 
          name: 'Standard (36% rule)', 
          affordablePrice: Math.round(maxHomePrice) 
        },
        { 
          name: 'Aggressive (43% rule)', 
          affordablePrice: Math.round(calculateAffordabilityByRule(0.43, annualIncome, monthlyDebt, downPayment, interestRate, loanTerm, propertyTaxRate, homeInsurance, hoa)) 
        }
      ]
    };
    
    setResult(newResult);
    localStorage.setItem('housingResults', JSON.stringify(newResult));
  }, []);

  useEffect(() => {
    if (savedSettings) {
      setAnnualIncome(savedSettings.annualIncome);
      setDownPayment(savedSettings.downPayment);
      setInterestRate(savedSettings.interestRate);
      setLoanTerm(savedSettings.loanTerm);
      setMonthlyDebt(savedSettings.monthlyDebt);
      setPropertyTaxRate(savedSettings.propertyTaxRate);
      setHomeInsurance(savedSettings.homeInsurance);
      setHoa(savedSettings.hoa);
      calculateWithSettings(savedSettings);
    }
  }, [savedSettings, calculateWithSettings]);

  const handleNumericInput = (e, setter, fieldName) => {
    const value = e.target.value;
    
    if (value === '') {
      setter(0);
      setInputStates(prev => ({ ...prev, [fieldName]: '' }));
      return;
    }

    setInputStates(prev => ({ ...prev, [fieldName]: value }));

    if (/^\d*\.?\d*$/.test(value)) {
      if (value !== '.') {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          setter(numValue);
        }
      }
    }
  };

  const getDisplayValue = (value, fieldName) => {
    if (value === 0) return '0';
    if (value === null) return '';
    
    const inputState = inputStates[fieldName];
    if (inputState && (inputState.endsWith('.') || inputState === '.')) {
      return inputState;
    }
    
    return value || '';
  };

  const calculateMonthlyMortgage = (loanAmount, rate, years) => {
    const monthlyRate = rate / 100 / 12;
    const totalPayments = years * 12;
    return loanAmount * (monthlyRate * Math.pow(1 + monthlyRate, totalPayments)) / (Math.pow(1 + monthlyRate, totalPayments) - 1);
  };

  const calculateAffordabilityByRule = (dtiRatio, annualIncome, monthlyDebt, downPayment, interestRate, loanTerm, propertyTaxRate, homeInsurance, hoa) => {
    const monthlyIncome = annualIncome / 12;
    const maxMonthlyPayment = (monthlyIncome * dtiRatio) - monthlyDebt;
    
    const monthlyRate = interestRate / 100 / 12;
    const totalPayments = loanTerm * 12;
    
    const maxPrincipalAndInterest = maxMonthlyPayment - 
      ((propertyTaxRate / 100) / 12 * (downPayment + 100000)) -
      homeInsurance - 
      hoa;
    
    const numerator = Math.pow(1 + monthlyRate, totalPayments) - 1;
    const denominator = monthlyRate * Math.pow(1 + monthlyRate, totalPayments);
    const maxLoanAmount = maxPrincipalAndInterest * (numerator / denominator);
    
    return maxLoanAmount + downPayment;
  };


  const saveSettings = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const settings = {
        annualIncome,
        downPayment,
        interestRate,
        loanTerm,
        monthlyDebt,
        propertyTaxRate,
        homeInsurance,
        hoa
      };

      const token = localStorage.getItem('token');
      if (token) {
        try {
          const { user: userApi } = await import('../../services/api');
          const response = await userApi.saveHousingSettings(settings);
          
          if (response?.data?.success) {
            console.log('Housing settings saved to database successfully');
          } else {
            throw new Error('Failed to save settings to database');
          }
        } catch (e) {
          console.error('Error saving to database:', e);
          setError('Could not save to your account. Check your connection.');
        }
      }
      
      localStorage.setItem('housingSettings', JSON.stringify(settings));
      calculateWithSettings(settings);
      
    } catch (err) {
      console.error('Error saving housing settings:', err);
      setError('Failed to save your settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const calculateHousingAffordability = () => {
    try {
      setLoading(true);
      setError(null);
      saveSettings();
    } catch (err) {
      console.error('Error calculating housing affordability:', err);
      setError('Failed to calculate housing affordability. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const renderScenarios = () => {
    if (!result?.scenarios) return null;
    
    return (
      <div className="affordability-scenarios card-style">
        <h4>Affordability by Different Rules</h4>
        <div className="scenario-cards">
          {result.scenarios.map((scenario, index) => (
            <div 
              key={index} 
              className={`scenario-card ${scenario.name.includes('36%') ? 'active' : ''}`}
            >
              <div className="scenario-name">{scenario.name}</div>
              <div className="scenario-value">${scenario.affordablePrice.toLocaleString()}</div>
              <div className="scenario-description">
                {index === 0 && "Most conservative approach"}
                {index === 1 && "Standard recommendation"}
                {index === 2 && "Maximum allowable by most lenders"}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  if (settingsLoading) {
    return <div className="loading">Loading your housing calculator...</div>;
  }
  
  return (
    <div className="housing-calculator">
      <h2>Housing Affordability Calculator</h2>
      <p className="calculator-description">
        Estimate how much home you can afford based on your income, expenses, and loan terms.
      </p>
      
      <div className="housing-content">
        <div className={`calculator-card ${isExpanded ? 'expanded' : ''}`}>
          <div 
            className="card-header"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <h3>Housing Settings</h3>
            <span className="expand-toggle">{isExpanded ? '▼' : '▲'}</span>
          </div>
          
          {isExpanded && (
            <div className="card-content">
              <div className="input-grid">
                <div className="input-group">
                  <label>Annual Income ($)</label>
                  <input 
                    type="text" 
                    value={getDisplayValue(annualIncome, 'annualIncome')}
                    onChange={(e) => handleNumericInput(e, setAnnualIncome, 'annualIncome')}
                  />
                </div>
                
                <div className="input-group">
                  <label>Down Payment ($)</label>
                  <input 
                    type="text" 
                    value={getDisplayValue(downPayment, 'downPayment')}
                    onChange={(e) => handleNumericInput(e, setDownPayment, 'downPayment')}
                  />
                </div>
                
                <div className="input-group">
                  <label>Interest Rate (%)</label>
                  <input 
                    type="text" 
                    value={getDisplayValue(interestRate, 'interestRate')}
                    onChange={(e) => handleNumericInput(e, setInterestRate, 'interestRate')}
                  />
                </div>
                
                <div className="input-group">
                  <label>Loan Term (years)</label>
                  <select 
                    value={loanTerm || 30} 
                    onChange={(e) => setLoanTerm(Number(e.target.value))}
                  >
                    <option value="30">30 years</option>
                    <option value="20">20 years</option>
                    <option value="15">15 years</option>
                    <option value="10">10 years</option>
                  </select>
                </div>
                
                <div className="input-group">
                  <label>Monthly Debt Payments ($)</label>
                  <input 
                    type="text" 
                    value={getDisplayValue(monthlyDebt, 'monthlyDebt')}
                    onChange={(e) => handleNumericInput(e, setMonthlyDebt, 'monthlyDebt')}
                  />
                </div>
                
                <div className="input-group">
                  <label>Property Tax Rate (%)</label>
                  <input 
                    type="text" 
                    value={getDisplayValue(propertyTaxRate, 'propertyTaxRate')}
                    onChange={(e) => handleNumericInput(e, setPropertyTaxRate, 'propertyTaxRate')}
                  />
                </div>
                
                <div className="input-group">
                  <label>Monthly Home Insurance ($)</label>
                  <input 
                    type="text" 
                    value={getDisplayValue(homeInsurance, 'homeInsurance')}
                    onChange={(e) => handleNumericInput(e, setHomeInsurance, 'homeInsurance')}
                  />
                </div>
                
                <div className="input-group">
                  <label>Monthly HOA Fees ($)</label>
                  <input 
                    type="text" 
                    value={getDisplayValue(hoa, 'hoa')}
                    onChange={(e) => handleNumericInput(e, setHoa, 'hoa')}
                  />
                </div>
              </div>

              <div className="button-container">
                <button onClick={calculateHousingAffordability} disabled={loading} className="minimal-action-button">
                  {loading ? 'Calculating...' : 'Calculate'}
                </button>
                <button onClick={saveSettings} disabled={loading} className="minimal-action-button">
                  Save Settings
                </button>
              </div>
            </div>
          )}
        </div>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        {settingsError && (
          <div className="error-message">
            {settingsError}
          </div>
        )}
        
        {result && (
          <div className="results-section">
            <div className="summary-card card-style">
              <h3>Your Housing Affordability</h3>
              <p className="result-value">${result.affordableHomePrice.toLocaleString()}</p>
              <p className="result-note">
                Based on your income and expenses, you can afford a home up to this price
                with a monthly payment of ${result.monthlyPayment.toLocaleString()}.
              </p>
              
              <div className="data-summary">
                <div className="summary-item">
                  <label>Monthly Payment</label>
                  <value>${result.monthlyPayment.toLocaleString()}</value>
                </div>
                <div className="summary-item">
                  <label>Debt-to-Income Ratio</label>
                  <value>{(result.debtToIncomeRatio * 100).toFixed(1)}%</value>
                </div>
              </div>
            </div>
            
            {renderScenarios()}
            
            <div className="payment-breakdown card-style">
              <h4>Monthly Payment Breakdown</h4>
              <div className="breakdown-items">
                <div className="breakdown-item">
                  <label>Mortgage (P&I)</label>
                  <value>${result.mortgagePayment.toLocaleString()}</value>
                </div>
                <div className="breakdown-item">
                  <label>Taxes & Insurance</label>
                  <value>${result.taxesAndInsurance.toLocaleString()}</value>
                </div>
                <div className="breakdown-item total">
                  <label>Total Payment</label>
                  <value>${result.monthlyPayment.toLocaleString()}</value>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HousingCalculator;
