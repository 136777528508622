import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth as authApi } from '../services/api';

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  loading: true,
  error: null,
  login: () => {},
  register: () => {},
  logout: () => {},
  setUser: () => {}
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);
  
  // Define logout function first to avoid circular reference
  const logout = () => {
    // Clear all authentication and user data
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    
    // Clear all calculator data
    localStorage.removeItem('retirementResults');
    localStorage.removeItem('retirementSettings');
    localStorage.removeItem('housingResults');
    localStorage.removeItem('investmentAccounts');
    
    // Reset user state
    setUser(null);
  };

  // Initialize auth state on mount
  useEffect(() => {
    let mounted = true;

    const initializeAuth = async () => {
      if (!mounted) return;
      
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const storedUser = localStorage.getItem('user');
        
        // If no token, finish initialization immediately
        if (!token) {
          if (mounted) {
            setUser(null);
            setLoading(false);
            setInitialized(true);
          }
          return;
        }

        // Set initial user state from localStorage if available
        if (storedUser) {
          setUser(JSON.parse(storedUser));
        }
        
        // Verify token and update user data
        const response = await authApi.getCurrentUser();
        
        if (!mounted) return;
        
        if (response.data && response.data.user) {
          setUser(response.data.user);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          
          if (response.data.user.retirementSettings) {
            localStorage.setItem('retirementSettings', 
              JSON.stringify(response.data.user.retirementSettings));
          }
        } else {
          throw new Error('Invalid server response');
        }
      } catch (err) {
        console.error('Auth init error:', err);
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    };

    // Start initialization
    initializeAuth();
    
    // Set up periodic token check
    const tokenCheckInterval = setInterval(() => {
      if (mounted && localStorage.getItem('token')) {
        const validateToken = async () => {
          try {
            const response = await authApi.getCurrentUser();
            if (mounted && response.data && response.data.user) {
              setUser(response.data.user);
            }
          } catch (err) {
            if (mounted) {
              console.error('Token validation error:', err);
              logout();
            }
          }
        };
        validateToken();
      }
    }, 300000);
    
    // Cleanup function
    return () => {
      mounted = false;
      clearInterval(tokenCheckInterval);
    };
  }, []);

  // Login
  const login = async (credentials) => {
    if (!initialized) return; // Prevent login attempts before initialization
    setLoading(true);
    try {
      setError(null);
      
      // First clear any existing data
      localStorage.removeItem('retirementResults');
      localStorage.removeItem('retirementSettings');
      localStorage.removeItem('housingResults');
      localStorage.removeItem('investmentAccounts');
      
      const response = await authApi.login(credentials);
      
      if (response.data.success === false) {
        throw new Error(response.data.message);
      }

      if (!response.data || !response.data.token || !response.data.user) {
        throw new Error('Invalid server response structure');
      }
      
      const { token, user } = response.data;
      
      // Set token first
      localStorage.setItem('token', token);
      
      // Then update user state and storage
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      
      // If user has retirement settings, save them to localStorage
      if (user.retirementSettings) {
        localStorage.setItem('retirementSettings', 
          JSON.stringify(user.retirementSettings));
      }
      
      return user;
    } catch (err) {
      setError(err.response?.data?.message || 'Login failed');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Register
  const register = async (userData) => {
    if (!initialized) return; // Prevent registration attempts before initialization
    setLoading(true);
    try {
      setError(null);
      
      // First clear any existing data
      localStorage.removeItem('retirementResults');
      localStorage.removeItem('retirementSettings');
      localStorage.removeItem('housingResults');
      localStorage.removeItem('investmentAccounts');
      
      const response = await authApi.register(userData);
      
      if (response.data.success === false) {
        throw new Error(response.data.message);
      }
      
      if (!response.data || !response.data.token || !response.data.user) {
        throw new Error('Invalid server response structure');
      }
      
      const { token, user } = response.data;
      
      // Set token first
      localStorage.setItem('token', token);
      
      // Then update user state and storage
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      
      // If user has retirement settings, save them to localStorage
      if (user.retirementSettings) {
        localStorage.setItem('retirementSettings', 
          JSON.stringify(user.retirementSettings));
      }
      
      return user;
    } catch (err) {
      setError(err.response?.data?.message || 'Registration failed');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Only consider authenticated after initialization
  const isAuthenticated = initialized && !!user;
  
  const value = {
    user,
    setUser,
    loading,
    error,
    login,
    register,
    logout,
    isAuthenticated,
    initialized
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
