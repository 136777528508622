import api from './api';

export const createBudget = async (timelineType, targetAmount) => {
  try {
    const response = await api.post('/user/budget/create', {
      timelineType,
      targetAmount: parseFloat(targetAmount)
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to create budget');
  }
};

export const getCurrentBudget = async () => {
  try {
    const response = await api.get('/user/budget/current');
    const { currentBudget, expenses, activeBudget } = response.data;

    return {
      currentBudget: {
        ...currentBudget,
        targetAmount: parseFloat(currentBudget?.targetAmount || 0)
      },
      expenses: expenses?.map(exp => ({
        ...exp,
        amount: parseFloat(exp.amount),
        date: new Date(exp.date).toISOString()
      })) || [],
      activeBudget
    };
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to get current budget');
  }
};

export const getBudgetHistory = async () => {
  try {
    const response = await api.get('/user/budget/history');
    return response.data.map(budget => ({
      ...budget,
      targetAmount: parseFloat(budget.targetAmount),
      expenses: budget.expenses?.map(exp => ({
        ...exp,
        amount: parseFloat(exp.amount),
        date: new Date(exp.date).toISOString()
      })) || []
    }));
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to get budget history');
  }
};

export const addExpense = async (expense) => {
  try {
    const response = await api.post('/user/budget/expenses', {
      ...expense,
      amount: parseFloat(expense.amount)
    });
    
    return {
      ...response.data,
      amount: parseFloat(response.data.amount),
      date: new Date(response.data.date).toISOString()
    };
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to add expense');
  }
};

export const deleteExpense = async (expenseId) => {
  try {
    await api.delete(`/user/budget/expenses/${expenseId}`);
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to delete expense');
  }
};

export const clearBudgetHistory = async () => {
  try {
    await api.post('/user/budget/clear-history');
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to clear budget history');
  }
};

export const switchBudget = async (budgetId) => {
  try {
    const response = await api.put(`/user/budget/switch/${budgetId}`);
    const { currentBudget, expenses } = response.data;

    return {
      currentBudget: {
        ...currentBudget,
        targetAmount: parseFloat(currentBudget.targetAmount)
      },
      expenses: expenses.map(exp => ({
        ...exp,
        amount: parseFloat(exp.amount),
        date: new Date(exp.date).toISOString()
      }))
    };
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to switch budget');
  }
};
