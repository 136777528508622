import React, { useState, useEffect } from 'react';

const RetirementCalculator = () => {
  const [initialAmount, setInitialAmount] = useState(10000);
  const [monthlyContribution, setMonthlyContribution] = useState(500);
  const [annualInterestRate, setAnnualInterestRate] = useState(7);
  const [years, setYears] = useState(30);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  // Define the calculateWithSettings function at component level with useCallback
  const calculateWithSettings = React.useCallback((settings) => {
    const { initialAmount, monthlyContribution, annualInterestRate, years } = settings;
    const projectedSavings = calculateProjectedSavings(
      initialAmount, 
      monthlyContribution, 
      annualInterestRate, 
      years
    );
    
    const newResult = {
      projectedSavings,
      dataPoints: generateDataPoints(initialAmount, monthlyContribution, annualInterestRate, years),
      projectionsByRate: generateProjectionsByRate(initialAmount, monthlyContribution, years)
    };
    
    setResult(newResult);
    localStorage.setItem('retirementResults', JSON.stringify(newResult));
  }, []);

  // Load user's saved retirement settings and results
  useEffect(() => {
    const loadSavedSettings = async () => {
      try {
        setSettingsLoaded(false);
        const token = localStorage.getItem('token');
        
        if (token) {
          try {
            const { user: userApi } = await import('../../services/api');
            const response = await userApi.getRetirementSettings();
            
            if (response?.data?.success) {
              const settings = response.data.retirementSettings;
              setInitialAmount(settings.initialAmount);
              setMonthlyContribution(settings.monthlyContribution);
              setAnnualInterestRate(settings.annualInterestRate);
              setYears(settings.years);
              calculateWithSettings(settings);
              
              const userData = JSON.parse(localStorage.getItem('user') || '{}');
              if (userData?.id) {
                userData.retirementSettings = settings;
                localStorage.setItem('user', JSON.stringify(userData));
                localStorage.setItem('retirementSettings', JSON.stringify(settings));
              }
            }
          } catch (e) {
            console.error('Error fetching settings from database:', e);
            const userDataStr = localStorage.getItem('user');
            if (userDataStr) {
              const userData = JSON.parse(userDataStr);
              if (userData?.retirementSettings) {
                const settings = userData.retirementSettings;
                setInitialAmount(settings.initialAmount);
                setMonthlyContribution(settings.monthlyContribution);
                setAnnualInterestRate(settings.annualInterestRate);
                setYears(settings.years);
                calculateWithSettings(settings);
              }
            }
          }
        } else {
          const savedSettingsStr = localStorage.getItem('retirementSettings');
          if (savedSettingsStr) {
            const settings = JSON.parse(savedSettingsStr);
            if (settings) {
              setInitialAmount(settings.initialAmount);
              setMonthlyContribution(settings.monthlyContribution);
              setAnnualInterestRate(settings.annualInterestRate);
              setYears(settings.years);
              calculateWithSettings(settings);
            }
          }
        }
      } catch (err) {
        console.error('Error loading retirement settings:', err);
      } finally {
        setSettingsLoaded(true);
      }
    };
    
    loadSavedSettings();
  }, [calculateWithSettings]);
  
  const saveSettings = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const settings = {
        initialAmount,
        monthlyContribution,
        annualInterestRate,
        years
      };
      
      const projectedSavings = calculateProjectedSavings(initialAmount, monthlyContribution, annualInterestRate, years);
      const newResult = {
        projectedSavings,
        dataPoints: generateDataPoints(initialAmount, monthlyContribution, annualInterestRate, years),
        projectionsByRate: generateProjectionsByRate(initialAmount, monthlyContribution, years)
      };
      
      setResult(newResult);
      
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const { user: userApi } = await import('../../services/api');
          const response = await userApi.saveRetirementSettings(settings);
          
          if (response?.data?.success) {
            const userData = JSON.parse(localStorage.getItem('user') || '{}');
            if (userData?.id) {
              userData.retirementSettings = response.data.retirementSettings || settings;
              localStorage.setItem('user', JSON.stringify(userData));
            }
            localStorage.setItem('retirementSettings', JSON.stringify(settings));
            localStorage.setItem('retirementResults', JSON.stringify(newResult));
          } else {
            throw new Error('Failed to save settings to database');
          }
        } catch (e) {
          setError('Could not save to your account. Check your connection.');
          localStorage.setItem('retirementSettings', JSON.stringify(settings));
          localStorage.setItem('retirementResults', JSON.stringify(newResult));
        }
      } else {
        localStorage.setItem('retirementSettings', JSON.stringify(settings));
        localStorage.setItem('retirementResults', JSON.stringify(newResult));
      }
      
    } catch (err) {
      setError('Failed to save your settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const generateDataPoints = (initial, monthly, rate, yrs) => {
    const monthlyRate = (rate / 100) / 12;
    const totalMonths = yrs * 12;
    let futureValue = initial;
    const dataPoints = [{ year: 0, value: initial }];
    
    for (let month = 1; month <= totalMonths; month++) {
      futureValue = futureValue * (1 + monthlyRate) + monthly;
      if (month % 12 === 0) {
        dataPoints.push({
          year: month / 12,
          value: Math.round(futureValue)
        });
      }
    }
    
    return dataPoints;
  };
  
  const generateProjectionsByRate = (initial, monthly, yrs) => {
    const alternativeRates = [
      { label: "Conservative (5%)", rate: 5 },
      { label: "Average (7%)", rate: 7 },
      { label: "Historical (10%)", rate: 10 }
    ];
    
    return alternativeRates.map(rateObj => {
      const monthlyRate = (rateObj.rate / 100) / 12;
      const totalMonths = yrs * 12;
      let projection = initial;
      
      for (let month = 1; month <= totalMonths; month++) {
        projection = projection * (1 + monthlyRate) + monthly;
      }
      
      return {
        label: rateObj.label,
        rate: rateObj.rate,
        projectedValue: Math.round(projection)
      };
    });
  };
  
  const calculateProjectedSavings = (initial, monthly, rate, yrs) => {
    const monthlyRate = (rate / 100) / 12;
    const totalMonths = yrs * 12;
    let futureValue = initial;
    
    for (let month = 1; month <= totalMonths; month++) {
      futureValue = futureValue * (1 + monthlyRate) + monthly;
    }
    
    return Math.round(futureValue);
  };

  const calculateRetirement = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const projectedSavings = calculateProjectedSavings(
        initialAmount, 
        monthlyContribution, 
        annualInterestRate, 
        years
      );
      
      const newResult = {
        projectedSavings,
        dataPoints: generateDataPoints(initialAmount, monthlyContribution, annualInterestRate, years),
        projectionsByRate: generateProjectionsByRate(initialAmount, monthlyContribution, years)
      };
      
      setResult(newResult);
      
      const settings = {
        initialAmount,
        monthlyContribution,
        annualInterestRate,
        years
      };
      
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const { user: userApi } = await import('../../services/api');
          const response = await userApi.saveRetirementSettings(settings);
          
          if (response?.data?.success) {
            const userData = JSON.parse(localStorage.getItem('user') || '{}');
            if (userData?.id) {
              userData.retirementSettings = response.data.retirementSettings || settings;
              localStorage.setItem('user', JSON.stringify(userData));
            }
            localStorage.setItem('retirementSettings', JSON.stringify(settings));
            localStorage.setItem('retirementResults', JSON.stringify(newResult));
          } else {
            throw new Error('Failed to save settings to database');
          }
        } catch (e) {
          setError('Calculation completed but could not save to your account.');
          localStorage.setItem('retirementSettings', JSON.stringify(settings));
          localStorage.setItem('retirementResults', JSON.stringify(newResult));
        }
      } else {
        localStorage.setItem('retirementSettings', JSON.stringify(settings));
        localStorage.setItem('retirementResults', JSON.stringify(newResult));
      }
    } catch (err) {
      setError('Failed to calculate retirement savings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const [hoveredBar, setHoveredBar] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  
  const renderChart = () => {
    if (!result?.dataPoints) return null;
    
    const maxValue = Math.max(...result.dataPoints.map(point => point.value));
    const chartHeight = 200;
    
    return (
      <div className="retirement-chart card-style">
        <h4>Growth Projection</h4>
        <div className="chart-container" style={{ height: `${chartHeight}px`, position: 'relative' }}>
          {result.dataPoints.map((point, index) => {
            const heightPercentage = (point.value / maxValue) * 100;
            const barWidth = `${100 / result.dataPoints.length}%`;
            const isHovered = hoveredBar === index;
            
            return (
              <div 
                key={index}
                className={`chart-bar ${isHovered ? 'active' : ''}`}
                style={{
                  height: `${heightPercentage}%`,
                  width: barWidth,
                  left: `${(index / result.dataPoints.length) * 100}%`,
                  position: 'absolute',
                  bottom: 0,
                  backgroundColor: isHovered ? '#2c6cb0' : '#4a90e2',
                  transition: 'height 0.5s ease, background-color 0.2s ease',
                  zIndex: isHovered ? 10 : 1
                }}
                onMouseEnter={() => setHoveredBar(index)}
                onMouseLeave={() => setHoveredBar(null)}
              />
            );
          })}
          
          {hoveredBar !== null && result.dataPoints[hoveredBar] && (
            <div className="chart-tooltip" style={{
              position: 'absolute',
              left: `${(hoveredBar / result.dataPoints.length) * 100}%`,
              bottom: `${(result.dataPoints[hoveredBar].value / maxValue) * 100 + 5}%`,
              transform: 'translateX(-50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '4px',
              fontSize: '12px',
              pointerEvents: 'none',
              zIndex: 20
            }}>
              <div><strong>Year {result.dataPoints[hoveredBar].year}</strong></div>
              <div>${result.dataPoints[hoveredBar].value.toLocaleString()}</div>
            </div>
          )}
        </div>
        <div className="chart-labels">
          <div>Year 0</div>
          <div>Year {years}</div>
        </div>
      </div>
    );
  };
  
  const renderRateComparison = () => {
    if (!result?.projectionsByRate) return null;
    
    return (
      <div className="rate-comparison card-style">
        <h4>Returns at Different Investment Rates</h4>
        <div className="rate-cards">
          {result.projectionsByRate.map((projection, index) => {
            const isActive = projection.rate === (selectedRate || annualInterestRate);
              
            return (
              <div 
                key={index} 
                className={`rate-card ${isActive ? 'active' : ''}`}
                onClick={() => setSelectedRate(projection.rate)}
              >
                <div className="rate-label">{projection.label}</div>
                <div className="rate-value">${projection.projectedValue.toLocaleString()}</div>
                <div className="rate-description">
                  {projection.rate === 10 && <span className="historical-note">(Historical average)</span>}
                </div>
                {isActive && (
                  <div className="rate-compare-note">
                    {projection.rate === annualInterestRate ? 
                      "Current selection" : 
                      `${projection.rate > annualInterestRate ? 'Increase' : 'Decrease'} of ${Math.abs(projection.rate - annualInterestRate)}%`
                    }
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {selectedRate !== null && selectedRate !== annualInterestRate && (
          <button 
            className="minimal-action-button"
            onClick={() => {
              setAnnualInterestRate(selectedRate);
              const settings = {
                initialAmount,
                monthlyContribution,
                annualInterestRate: selectedRate,
                years
              };
              calculateWithSettings(settings);
            }}
          >
            Apply {selectedRate}% rate
          </button>
        )}
      </div>
    );
  };

  if (!settingsLoaded) {
    return <div className="loading">Loading your retirement settings...</div>;
  }

  return (
    <div className="retirement-calculator">
      <h2>Retirement Calculator</h2>
      <p className="calculator-description">
        Estimate your retirement savings based on your current savings, monthly contributions, and expected interest rate.
      </p>
      
      <div className="retirement-content">
        <div className={`calculator-card ${isExpanded ? 'expanded' : ''}`}>
          <div 
            className="card-header"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <h3>Retirement Settings</h3>
            <span className="expand-toggle">{isExpanded ? '▼' : '▲'}</span>
          </div>
          
          {isExpanded && (
            <div className="card-content">
              <div className="input-grid">
                <div className="input-group">
                  <label>Initial Amount ($)</label>
                  <input 
                    type="text" 
                    value={initialAmount === 0 ? '' : initialAmount} 
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*$/.test(value)) {
                        setInitialAmount(value === '' ? 0 : Number(value));
                      }
                    }}
                  />
                </div>
                
                <div className="input-group">
                  <label>Monthly Contribution ($)</label>
                  <input 
                    type="text" 
                    value={monthlyContribution === 0 ? '' : monthlyContribution} 
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*$/.test(value)) {
                        setMonthlyContribution(value === '' ? 0 : Number(value));
                      }
                    }}
                  />
                </div>
                
                <div className="input-group">
                  <label>Annual Interest Rate (%)</label>
                  <input 
                    type="text" 
                    value={annualInterestRate === 0 ? '' : annualInterestRate} 
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        setAnnualInterestRate(value === '' ? 0 : Number(value));
                      }
                    }}
                  />
                </div>
                
                <div className="input-group">
                  <label>Years until Retirement</label>
                  <input 
                    type="text" 
                    value={years === 0 ? '' : years} 
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*$/.test(value)) {
                        setYears(value === '' ? 0 : Number(value));
                      }
                    }}
                  />
                </div>
              </div>

              <div className="button-container">
                <button onClick={calculateRetirement} disabled={loading} className="minimal-action-button">
                  {loading ? 'Calculating...' : 'Calculate'}
                </button>
                <button onClick={saveSettings} disabled={loading} className="minimal-action-button">
                  Save Settings
                </button>
              </div>
            </div>
          )}
        </div>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
        {result && (
          <div className="results-section">
            <div className="summary-card card-style">
              <h3>Estimated Retirement Savings</h3>
              <p className="result-value">${result.projectedSavings.toLocaleString()}</p>
              <p className="result-note">
                This estimate is based on a {annualInterestRate}% annual return over {years} years
                with a monthly contribution of ${monthlyContribution}.
              </p>
              
              <div className="data-summary">
                <div className="summary-item">
                  <label>At halfway point ({Math.floor(years / 2)} years)</label>
                  <value>${result.dataPoints[Math.floor(years / 2)].value.toLocaleString()}</value>
                </div>
                <div className="summary-item">
                  <label>Total Growth</label>
                  <value>{((result.projectedSavings / (initialAmount + (monthlyContribution * years * 12))) * 100 - 100).toFixed(1)}%</value>
                </div>
              </div>
            </div>
            
            {renderChart()}
            {renderRateComparison()}
          </div>
        )}
      </div>
    </div>
  );
};

export default RetirementCalculator;
