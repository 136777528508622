import axios from 'axios';

// Create an axios instance with default config
// Determine API URL based on environment
const getBaseURL = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  
  // In production, use the same domain with /api path
  return process.env.NODE_ENV === 'production'
    ? '/api'  // This will use the same domain as the frontend
    : 'http://localhost:5001/api';
};

// Configure axios with retries and better timeout handling
const api = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: process.env.NODE_ENV === 'production' ? 15000 : 10000, // Reduced from 30s to 15s
  // Retry configuration
  validateStatus: function (status) {
    return status >= 200 && status < 500; // Don't retry on client errors
  }
});

// Add a request interceptor to add auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Helper to determine if we should retry the request
const shouldRetry = (error) => {
  const { config, code, message } = error;
  const retryCount = config.__retryCount || 0;
  
  // Only retry on network errors or 5xx errors
  return (
    retryCount < 2 && // Maximum 2 retries
    (code === 'ECONNABORTED' || 
     message.includes('timeout') ||
     message.includes('Network Error') ||
     (error.response && error.response.status >= 500))
  );
};

// Add a response interceptor with retry logic
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    
    if (shouldRetry(error)) {
      originalConfig.__retryCount = (originalConfig.__retryCount || 0) + 1;
      
      // Exponential backoff
      const backoff = Math.min(1000 * (Math.pow(2, originalConfig.__retryCount) - 1), 3000);
      await new Promise(resolve => setTimeout(resolve, backoff));
      
      // Retry the request
      return api(originalConfig);
    }
    
    return Promise.reject(error);
  }
);

// Calculator API methods
export const calculators = {
  // Retirement calculator
  calculateRetirement: (data) => api.post('/calculators/retirement', data),
  getRetirementSettings: () => api.get('/calculators/retirement/settings'),
  saveRetirementSettings: (data) => api.put('/calculators/retirement/settings', data),
  
  // Add other calculator methods
  calculateHousingAffordability: (data) => api.post('/calculators/housing', data),
  calculateInvestment: (data) => api.post('/calculators/investment', data),
};

// Auth API methods
export const auth = {
  login: (credentials) => {
    return api.post('/auth/login', credentials);
  },
  register: (userData) => {
    return api.post('/auth/register', userData);
  },
  getCurrentUser: () => {
    return api.get('/auth/me');
  },
};

// User API methods
export const user = {
  getProfile: () => {
    return api.get('/user/profile');
  },
  updateProfile: (data) => {
    return api.put('/user/profile', data);
  },
  saveRetirementSettings: (data) => {
    return api.put('/user/settings/retirement', data);
  },
  getRetirementSettings: () => {
    return api.get('/user/settings/retirement');
  },
  saveHousingSettings: (data) => {
    return api.put('/user/settings/housing', data);
  },
  getHousingSettings: () => {
    return api.get('/user/settings/housing');
  },
  // Investment accounts methods
  saveInvestmentAccounts: (accounts) => {
    // Ensure we're sending an object with an accounts array, not a string
    return api.put('/user/settings/investments', { accounts: Array.isArray(accounts) ? accounts : [] });
  },
  getInvestmentAccounts: () => {
    return api.get('/user/settings/investments');
  }
};

export default api;
