import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  createBudget,
  getCurrentBudget,
  getBudgetHistory,
  addExpense,
  deleteExpense,
  switchBudget,
  clearBudgetHistory
} from '../../services/budget';
import BudgetInput from './BudgetInput';
import BudgetHistory from './BudgetHistory';

const BUDGET_STORAGE_KEY = 'lifecompass_current_budget';

const BudgetTracker = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const cachedBudgetData = localStorage.getItem(BUDGET_STORAGE_KEY);
  const [showTimelineModal, setShowTimelineModal] = useState(!cachedBudgetData);
  const [showBudgetInput, setShowBudgetInput] = useState(false);
  const [showBudgetHistory, setShowBudgetHistory] = useState(false);
  const [selectedTimelineType, setSelectedTimelineType] = useState('weekly');
  const [currentBudget, setCurrentBudget] = useState(cachedBudgetData ? JSON.parse(cachedBudgetData).currentBudget : null);
  const [expenses, setExpenses] = useState(cachedBudgetData ? JSON.parse(cachedBudgetData).expenses || [] : []);
  const [budgetHistory, setBudgetHistory] = useState([]);
  const [newExpense, setNewExpense] = useState({
    title: '', 
    amount: '', 
    date: new Date().toISOString().split('T')[0]
  });
  const [isLoading, setIsLoading] = useState(cachedBudgetData ? false : true);
  const [error, setError] = useState(null);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  
  const handleTimelineSelect = (type) => {
    setSelectedTimelineType(type);
    setShowTimelineModal(false);
    setShowBudgetInput(true);
  };

  useEffect(() => {
    const loadBudgetData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const cachedBudget = localStorage.getItem(BUDGET_STORAGE_KEY);
        if (cachedBudget) {
          const parsed = JSON.parse(cachedBudget);
          setCurrentBudget(parsed.currentBudget);
          setExpenses(parsed.expenses || []);
        }

        const { currentBudget: budget, expenses: currentExpenses } = await getCurrentBudget();
        if (budget) {
          const budgetToUse = {
            ...budget,
            targetAmount: parseFloat(budget.targetAmount)
          };
          setCurrentBudget(budgetToUse);
          setShowTimelineModal(false);
          
          const formattedExpenses = (currentExpenses || []).map(exp => ({
            ...exp,
            amount: parseFloat(exp.amount),
            date: new Date(exp.date).toISOString()
          }));
          setExpenses(formattedExpenses);
          
          localStorage.setItem(BUDGET_STORAGE_KEY, JSON.stringify({
            currentBudget: budgetToUse,
            expenses: formattedExpenses
          }));
        } else if (!cachedBudget) {
          setShowTimelineModal(true);
        }
        
        const history = await getBudgetHistory();
        setBudgetHistory(Array.isArray(history) ? history : []);
      } catch (error) {
        console.error('Failed to load budget data:', error);
        setError('Failed to load budget data. Please try again later.');
        const cachedBudget = localStorage.getItem(BUDGET_STORAGE_KEY);
        if (!cachedBudget) {
          setShowTimelineModal(true);
        }
      } finally {
        setIsLoading(false);
      }
    };
    loadBudgetData();
  }, []);

  const calculateTotals = () => {
    try {
      const targetAmount = currentBudget?.targetAmount || 0;
      if (!Array.isArray(expenses)) {
        return { total: 0, remaining: targetAmount, percentage: 0 };
      }
      const total = expenses.reduce((sum, exp) => sum + parseFloat(exp.amount || 0), 0);
      return {
        total,
        remaining: targetAmount - total,
        percentage: targetAmount > 0 ? (total / targetAmount) * 100 : 0
      };
    } catch (err) {
      console.error('Calculation error:', err);
      return { total: 0, remaining: 0, percentage: 0 };
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount || 0);
  };

  const handleBudgetSubmit = async (budgetAmount) => {
    try {
      setError(null);
      const newBudget = await createBudget(selectedTimelineType, budgetAmount);
      
      const budgetWithParsedAmount = {
        ...newBudget,
        targetAmount: parseFloat(budgetAmount)
      };
      
      setCurrentBudget(budgetWithParsedAmount);
      setExpenses([]);
      setShowBudgetInput(false);
      
      localStorage.setItem(BUDGET_STORAGE_KEY, JSON.stringify({
        currentBudget: budgetWithParsedAmount,
        expenses: []
      }));
      
      const history = await getBudgetHistory();
      setBudgetHistory(Array.isArray(history) ? history : []);
    } catch (error) {
      console.error('Failed to create budget:', error);
      setError('Failed to create budget. Please try again.');
    }
  };

  const handleAddExpense = async (e) => {
    e.preventDefault();
    if (!newExpense.title || !newExpense.amount) return;

    try {
      setError(null);
      const expenseToAdd = {
        ...newExpense,
        amount: parseFloat(newExpense.amount),
        date: newExpense.date || new Date().toISOString().split('T')[0]
      };

      const addedExpense = await addExpense(expenseToAdd);
      if (!addedExpense) throw new Error('Failed to add expense');

      const updatedExpenses = [
        {
          ...addedExpense,
          amount: parseFloat(addedExpense.amount),
          date: new Date(addedExpense.date).toISOString()
        },
        ...expenses
      ];
      
      setExpenses(updatedExpenses);
      setNewExpense({ 
        title: '', 
        amount: '', 
        date: new Date().toISOString().split('T')[0]
      });

      localStorage.setItem(BUDGET_STORAGE_KEY, JSON.stringify({
        currentBudget,
        expenses: updatedExpenses
      }));
    } catch (error) {
      console.error('Failed to add expense:', error);
      setError('Failed to add expense. Please try again.');
    }
  };

  const handleDeleteExpense = async (expenseId) => {
    try {
      await deleteExpense(expenseId);
      const updatedExpenses = expenses.filter(e => e._id !== expenseId);
      setExpenses(updatedExpenses);
      
      localStorage.setItem(BUDGET_STORAGE_KEY, JSON.stringify({
        currentBudget,
        expenses: updatedExpenses
      }));
    } catch (error) {
      setError('Failed to delete expense. Please try again.');
    }
  };

  const handleClearHistory = async () => {
    if (window.confirm('Are you sure you want to clear all completed and archived budgets? This cannot be undone.')) {
      try {
        await clearBudgetHistory();
        // Refresh budget history after clearing
        const history = await getBudgetHistory();
        setBudgetHistory(Array.isArray(history) ? history : []);
        setError(null);
      } catch (error) {
        console.error('Failed to clear budget history:', error);
        setError('Failed to clear budget history. Please try again.');
      }
    }
  };

  const handleBudgetSwitch = async (budgetId) => {
    try {
      setError(null);
      const { currentBudget: newBudget, expenses: newExpenses } = await switchBudget(budgetId);
      
      const budgetToUse = {
        ...newBudget,
        targetAmount: parseFloat(newBudget.targetAmount)
      };
      
      const formattedExpenses = newExpenses.map(exp => ({
        ...exp,
        amount: parseFloat(exp.amount),
        date: new Date(exp.date).toISOString()
      }));

      setCurrentBudget(budgetToUse);
      setExpenses(formattedExpenses);
      setShowBudgetHistory(false);

      localStorage.setItem(BUDGET_STORAGE_KEY, JSON.stringify({
        currentBudget: budgetToUse,
        expenses: formattedExpenses
      }));
    } catch (error) {
      console.error('Failed to switch budget:', error);
      setError('Failed to switch budget. Please try again.');
    }
  };

  const { total: totalExpenses, remaining: remainingBudget, percentage: percentageUsed } = calculateTotals();

  return (
    <div className="budget-tracker">
      <div className="budget-header">
        {error && (
          <div className="error-message">
            <p>{error}</p>
          </div>
        )}

        {currentBudget && (
          <>
            <div className="action-bar">
              <div className="action-buttons">
                <button 
                  className="switch-budget-button"
                  onClick={() => setShowBudgetHistory(true)}
                >
                  Switch Budget
                </button>
                <button 
                  className="reset-button"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to start a new budget? This will clear your current budget.')) {
                      localStorage.removeItem(BUDGET_STORAGE_KEY);
                      setCurrentBudget(null);
                      setExpenses([]);
                      setShowTimelineModal(true);
                    }
                  }}
                >
                  Start New Budget
                </button>
              </div>
            </div>

            <div className="budget-period">
              Budget Period: <strong>{formatDate(currentBudget.startDate)}</strong> to <strong>{formatDate(currentBudget.endDate)}</strong>
            </div>

            <div className="quick-add-section container">
              <form className="expense-form" onSubmit={handleAddExpense}>
                <div className="expense-form-inputs">
                  <input
                    type="text"
                    placeholder="Expense title"
                    value={newExpense.title}
                    required
                    onChange={(e) => setNewExpense({...newExpense, title: e.target.value})}
                  />
                  <input
                    type="number"
                    placeholder="Amount"
                    value={newExpense.amount}
                    required
                    min="0"
                    step="0.01"
                    onChange={(e) => setNewExpense({...newExpense, amount: e.target.value})}
                  />
                  <input
                    type="date"
                    value={newExpense.date}
                    onChange={(e) => setNewExpense({...newExpense, date: e.target.value})}
                  />
                  <button type="submit" className="add-button">Add</button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>

      {!isLoading && showTimelineModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Select Budget Timeline</h2>
            <div className="timeline-options">
              {['weekly', 'monthly', 'custom'].map((type) => (
                <button
                  key={type}
                  className={`timeline-option ${selectedTimelineType === type ? 'selected' : ''}`}
                  onClick={() => handleTimelineSelect(type)}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {showBudgetInput && (
        <BudgetInput
          timelineType={selectedTimelineType}
          onSubmit={handleBudgetSubmit}
          onCancel={() => {
            setShowBudgetInput(false);
            setShowTimelineModal(true);
          }}
        />
      )}

      {showBudgetHistory && (
        <BudgetHistory
          budgets={budgetHistory}
          onSelect={handleBudgetSwitch}
          onClose={() => setShowBudgetHistory(false)}
        />
      )}

      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : !currentBudget ? (
        <div className="no-budget-message">
          <p>No active budget. Please create one to get started.</p>
        </div>
      ) : (
        <>
          <div className="budget-overview">
            <div className="budget-circle" style={{
              background: `conic-gradient(
                ${percentageUsed > 90 ? '#ff3b30' : percentageUsed > 75 ? '#ff9500' : '#34c759'} 
                ${percentageUsed}%, #e1e1e1 ${percentageUsed}%)`
            }}>
              <div className="budget-circle-inner">
                <h3>{formatCurrency(remainingBudget)}</h3>
                <p>Remaining</p>
              </div>
            </div>
            <div className="budget-stats">
              <div className="stat-item">
                <label>Total Budget</label>
                <h4>{formatCurrency(currentBudget.targetAmount)}</h4>
              </div>
              <div className="stat-item">
                <label>Spent</label>
                <h4>{formatCurrency(totalExpenses)}</h4>
              </div>
            </div>
          </div>

          <div className="expense-list">
            <h3>Recent Expenses</h3>
            {budgetHistory.some(b => b.status === 'archived' || b.status === 'completed') && (
              <button className="clear-history" onClick={handleClearHistory}>
                Clear Old Budgets
              </button>
            )}
            {Array.isArray(expenses) && expenses.length > 0 ? (
              expenses.map((expense, index) => (
                <div 
                  key={index} 
                  className="expense-item"
                  onClick={() => {
                    if (window.confirm('Delete this expense?')) {
                      handleDeleteExpense(expense._id);
                    }
                  }}
                >
                  <div className="expense-info">
                    <h4>{expense.title}</h4>
                    <span>{formatDate(expense.date)}</span>
                  </div>
                  <div className="expense-amount">
                    {formatCurrency(expense.amount)}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-expenses">No expenses yet</div>
            )}
          </div>

          <div className="projections">
            <h3>Projected Monthly Spending</h3>
            <div className="projection-amount">
              {formatCurrency((totalExpenses / (expenses.length || 1)) * 30)}
              <span className="projection-label">/month</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BudgetTracker;
