import React from 'react';

const BudgetHistory = ({ budgets, onSelect, onClose }) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const getBudgetProgress = (budget) => {
    const totalExpenses = budget.expenses.reduce((sum, exp) => sum + exp.amount, 0);
    return (totalExpenses / budget.targetAmount) * 100;
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content budget-history">
        <div className="modal-header">
          <h2>Budget History</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="budget-list">
          {budgets.length === 0 ? (
            <div className="no-budgets">
              <p>No previous budgets found</p>
            </div>
          ) : (
            budgets.map((budget) => (
              <div 
                key={budget._id} 
                className={`budget-item ${budget.status === 'active' ? 'active' : ''}`}
                onClick={() => onSelect(budget._id)}
              >
                <div className="budget-item-header">
                  <h3>{budget.timelineType.charAt(0).toUpperCase() + budget.timelineType.slice(1)} Budget</h3>
                  <span className={`status-badge ${budget.status}`}>
                    {budget.status}
                  </span>
                </div>
                <div className="budget-item-details">
                  <div className="budget-dates">
                    <span>{formatDate(budget.startDate)}</span>
                    <span className="date-separator">→</span>
                    <span>{formatDate(budget.endDate)}</span>
                  </div>
                  <div className="budget-amount">
                    Target: {formatCurrency(budget.targetAmount)}
                  </div>
                  <div className="budget-progress">
                    <div className="progress-bar">
                      <div 
                        className="progress-fill"
                        style={{
                          width: `${Math.min(getBudgetProgress(budget), 100)}%`,
                          backgroundColor: getBudgetProgress(budget) > 90 
                            ? '#ff3b30' 
                            : getBudgetProgress(budget) > 75 
                              ? '#ff9500' 
                              : '#34c759'
                        }}
                      />
                    </div>
                    <span className="progress-text">
                      {formatCurrency(budget.expenses.reduce((sum, exp) => sum + exp.amount, 0))} spent
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="modal-footer">
          <button className="secondary-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default BudgetHistory;
