import React from 'react';
import { ReactComponent as Logo } from './assets/logo.svg';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import './styles/main.css';
import './styles/budget.css';

// Import pages
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';

// Import components
import RetirementCalculator from './components/calculators/RetirementCalculator';
import HousingCalculator from './components/calculators/HousingCalculator';
import InvestmentTracker from './components/calculators/InvestmentTracker';
import BudgetTracker from './components/calculators/BudgetTracker';
import Login from './components/auth/Login';
import Register from './components/auth/Register';

// Import context
import { useAuth } from './context/AuthContext';

const AppContent = () => {
  const navigate = useNavigate();
  const { user, logout, isAuthenticated, loading, initialized } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close mobile menu when clicking a link or outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileMenuOpen && !event.target.closest('nav')) {
        setIsMobileMenuOpen(false);
      }
    };

    const handleLinkClick = () => {
      setIsMobileMenuOpen(false);
    };

    document.addEventListener('click', handleClickOutside);
    document.querySelectorAll('nav a').forEach(link => {
      link.addEventListener('click', handleLinkClick);
    });

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.querySelectorAll('nav a').forEach(link => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [isMobileMenuOpen]);

  // Only show loading state during initial auth check
  if (!initialized && loading) {
    return (
      <div className="loading-container" style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}>
        <div style={{ textAlign: 'center' }}>
          <p style={{ marginBottom: '10px' }}>Loading...</p>
          <div className="spinner"></div>
        </div>
      </div>
    );
  }
  
  const handleLogout = async (e) => {
    e.preventDefault();
    logout();
    navigate('/', { replace: true });
  };

  return (
    <div className="App">
      <header className="App-header">
        <Link to="/" className="logo-link">
          <Logo className="app-logo" />
        </Link>
        <nav>
          <button 
            className={`hamburger-menu ${isMobileMenuOpen ? 'active' : ''}`}
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="nav-container">
            <ul className={isMobileMenuOpen ? 'active' : ''}>
              {isAuthenticated ? (
                <>
                  <li><Link to="/dashboard">Dashboard</Link></li>
                  <li><Link to="/calculators/retirement">Retirement</Link></li>
                  <li><Link to="/calculators/housing">Housing</Link></li>
                  <li><Link to="/calculators/investments">Investments</Link></li>
                  <li><Link to="/calculators/budget">Budgeting</Link></li>
                  <li><Link to="/profile">Profile</Link></li>
                  {/* User info section for mobile */}
                  <li className="mobile-menu-user-info">
                    <div className="user-info">
                      <span className="signed-in-text">Signed in as: {user?.firstName || user?.username}</span>
                      <button className="logout-button" onClick={handleLogout}>Logout</button>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li><Link to="/login">Login</Link></li>
                  <li><Link to="/register">Register</Link></li>
                </>
              )}
            </ul>
            {/* Desktop-only user info */}
            {isAuthenticated && (
              <div className="user-info desktop-only">
                <span className="signed-in-text">Signed in as: {user?.firstName || user?.username}</span>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
              </div>
            )}
          </div>
        </nav>
      </header>
      
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          
          {/* Protected Routes - Redirect to login if not authenticated */}
          <Route
            path="/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/calculators/retirement"
            element={isAuthenticated ? <RetirementCalculator /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/calculators/housing"
            element={isAuthenticated ? <HousingCalculator /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/calculators/investments"
            element={isAuthenticated ? <InvestmentTracker /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/calculators/budget"
            element={isAuthenticated ? <BudgetTracker /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/profile"
            element={isAuthenticated ? <Profile /> : <Navigate to="/login" replace />}
          />
          
          {/* Auth Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          
          <Route path="*" element={<div><h2>404</h2><p>Page not found</p></div>} />
        </Routes>
      </main>
      
      <footer className="App-footer">
        <p>&copy; {new Date().getFullYear()} LifeCompass. All rights reserved.</p>
      </footer>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
