import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/welcome.css';

const Welcome = () => {
  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <h1 className="welcome-title fade-in">
          Welcome to <span className="highlight">LifeCompass</span>
        </h1>
        
        <p className="welcome-description slide-up">
          Navigate your financial future with confidence. Plan for retirement, 
          track investments, and make informed housing decisions all in one place.
        </p>
        
        <div className="welcome-cards slide-up-delay">
          <div className="feature-card">
            <i className="fas fa-chart-line"></i>
            <h3>Investment Tracking</h3>
            <p>Monitor and project your portfolio growth with intuitive visualizations</p>
          </div>
          
          <div className="feature-card">
            <i className="fas fa-home"></i>
            <h3>Housing Calculator</h3>
            <p>Make informed decisions about home buying with our advanced calculator</p>
          </div>
          
          <div className="feature-card">
            <i className="fas fa-piggy-bank"></i>
            <h3>Retirement Planning</h3>
            <p>Plan your retirement with confidence using our comprehensive tools</p>
          </div>
        </div>
        
        <div className="welcome-actions fade-in-delay">
          <Link to="/register" className="btn btn-primary">Get Started</Link>
          <Link to="/login" className="btn btn-secondary">Sign In</Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
