import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const RetirementChart = ({ retirementSettings }) => {
  const generateDataPoints = () => {
    const {
      initialAmount,
      monthlyContribution,
      annualInterestRate,
      years
    } = retirementSettings;

    const monthlyRate = (annualInterestRate / 100) / 12;
    const data = [];
    let currentValue = initialAmount;
    let totalContributions = initialAmount;

    // Add initial point
    data.push({
      year: 0,
      value: currentValue,
      contributions: totalContributions
    });

    // Generate yearly data points
    for (let year = 1; year <= years; year++) {
      // Calculate monthly compounding
      for (let month = 1; month <= 12; month++) {
        currentValue = currentValue * (1 + monthlyRate) + monthlyContribution;
        totalContributions += monthlyContribution;
      }

      data.push({
        year,
        value: Math.round(currentValue),
        contributions: Math.round(totalContributions)
      });
    }

    return data;
  };

  const formatYAxis = (value) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `$${(value / 1000).toFixed(1)}K`;
    }
    return `$${value}`;
  };

  const formatTooltip = (value, name) => {
    let label;
    if (name === 'value') {
      label = 'Projected Growth';
    } else if (name === 'contributions') {
      label = 'Total Contributions';
    }
    return [`$${value.toLocaleString()}`, label];
  };

  const data = generateDataPoints();

  return (
    <div style={{ width: '100%', height: 550, marginTop: '20px', marginBottom: '30px' }}>
      <ResponsiveContainer>
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 65, bottom: 60 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#e2e8f0" />
          <XAxis 
            dataKey="year" 
            label={{ value: 'Time (Years)', position: 'bottom', dy: 45 }}
            stroke="#64748b"
            tick={{ fill: '#64748b' }}
            tickMargin={15}
          />
          <YAxis 
            tickFormatter={formatYAxis}
            label={{ value: 'Portfolio Value', angle: -90, position: 'insideLeft', offset: -45 }}
            stroke="#64748b"
            tick={{ fill: '#64748b' }}
            tickMargin={5}
          />
          <Tooltip 
            formatter={formatTooltip}
            contentStyle={{
              backgroundColor: 'white',
              border: '1px solid #e2e8f0',
              borderRadius: '8px',
              padding: '8px 12px'
            }}
          />
          
          {/* Current value line */}
          <Area
            type="monotone"
            dataKey="contributions"
            stroke="#10b981"
            strokeDasharray="4 4"
            strokeWidth={2}
            fill="none"
            name="Total Contributions"
            isAnimationActive={false}
          />
          
          {/* Projected value line */}
          <Area
            type="monotone"
            dataKey="value"
            stroke="#6366f1"
            strokeWidth={2}
            fill="url(#retirementGradient)"
            name="Projected Growth"
            isAnimationActive={false}
          />
          
          <defs>
            <linearGradient id="retirementGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#6366f1" stopOpacity={0.15}/>
              <stop offset="95%" stopColor="#6366f1" stopOpacity={0.05}/>
            </linearGradient>
          </defs>
        </AreaChart>
      </ResponsiveContainer>
      
      <div className="chart-legend" style={{ marginTop: '30px', paddingBottom: '20px' }}>
        <div className="legend-item">
          <span className="line-style dashed"></span>
          <span>Total Contributions</span>
        </div>
        <div className="legend-item">
          <span className="line-style solid"></span>
          <span>Projected Growth</span>
        </div>
      </div>
    </div>
  );
};

export default RetirementChart;
