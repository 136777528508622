import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useHousingSettings } from '../hooks/useHousingSettings';
import PortfolioChart from '../components/dashboard/PortfolioChart';
import RetirementChart from '../components/dashboard/RetirementChart';

const Dashboard = () => {
  const { isAuthenticated, user } = useAuth();
  const { settings: housingSettings, loading: housingLoading } = useHousingSettings();
  
  const [retirementData, setRetirementData] = useState({
    projectedSavings: 0,
    loading: true
  });
  
  const [housingData, setHousingData] = useState({
    affordableHomePrice: 0,
    loading: true
  });
  
  const [investmentData, setInvestmentData] = useState({
    portfolioValue: 0,
    loading: true,
    accounts: []
  });

  // Update housing data when settings load
  // Calculate affordable home price when housing settings load
  useEffect(() => {
    if (!housingSettings) return;
    
    const {
      annualIncome,
      monthlyDebt,
      downPayment,
      interestRate,
      loanTerm,
      propertyTaxRate,
      homeInsurance,
      hoa
    } = housingSettings;

    // Calculate affordable home price
    const calculateAffordableHomePrice = () => {
      const monthlyIncome = annualIncome / 12;
      const maxMonthlyPayment = (monthlyIncome * 0.36) - monthlyDebt;
      const monthlyRate = interestRate / 100 / 12;
      const totalPayments = loanTerm * 12;
      
      const maxPrincipalAndInterest = maxMonthlyPayment - 
        ((propertyTaxRate / 100) / 12 * (downPayment + 100000)) -
        homeInsurance - 
        hoa;
      
      const numerator = Math.pow(1 + monthlyRate, totalPayments) - 1;
      const denominator = monthlyRate * Math.pow(1 + monthlyRate, totalPayments);
      const maxLoanAmount = maxPrincipalAndInterest * (numerator / denominator);
      
      return Math.round(maxLoanAmount + downPayment);
    };

    setHousingData({
      affordableHomePrice: calculateAffordableHomePrice(),
      loading: false
    });
  }, [housingSettings]);

  // Helper functions for retirement calculations
  const calculateRetirementFutureValue = useCallback((settings) => {
    const { initialAmount, monthlyContribution, annualInterestRate, years } = settings;
    const monthlyRate = (annualInterestRate / 100) / 12;
    const totalMonths = years * 12;
    
    let futureValue = initialAmount;
    for (let month = 1; month <= totalMonths; month++) {
      futureValue = futureValue * (1 + monthlyRate) + monthlyContribution;
    }
    
    return Math.round(futureValue);
  }, []);
  
  const generateRetirementDataPoints = useCallback((settings) => {
    const { initialAmount, monthlyContribution, annualInterestRate, years } = settings;
    const monthlyRate = (annualInterestRate / 100) / 12;
    const totalMonths = years * 12;
    
    let futureValue = initialAmount;
    const dataPoints = [{ year: 0, value: initialAmount }];
    
    for (let month = 1; month <= totalMonths; month++) {
      futureValue = futureValue * (1 + monthlyRate) + monthlyContribution;
      
      if (month % 12 === 0) {
        dataPoints.push({
          year: month / 12,
          value: Math.round(futureValue)
        });
      }
    }
    
    return dataPoints;
  }, []);

  // Load retirement and investment data
  useEffect(() => {
    if (!isAuthenticated) {
      setRetirementData({
        projectedSavings: 0,
        loading: false
      });
      
      setInvestmentData({
            portfolioValue: 0,
            loading: false,
            accounts: []
      });
      
      return;
    }

    // Fetch retirement data
    const fetchRetirementData = async () => {
      try {
        // Try to get data from API first
        const token = localStorage.getItem('token');
        if (token) {
          try {
            const { user: userApi } = await import('../services/api');
            const response = await userApi.getRetirementSettings();
            
            if (response?.data?.success) {
              const settings = response.data.retirementSettings;
              const projectedSavings = calculateRetirementFutureValue(settings);
              
              setRetirementData({
                projectedSavings,
                loading: false
              });
              
              // Store data in localStorage
              const userData = JSON.parse(localStorage.getItem('user') || '{}');
              if (userData?.id) {
                userData.retirementSettings = settings;
                localStorage.setItem('user', JSON.stringify(userData));
              }
              
              localStorage.setItem('retirementSettings', JSON.stringify(settings));
              
              const calculatedResults = {
                projectedSavings,
                dataPoints: generateRetirementDataPoints(settings)
              };
              localStorage.setItem('retirementResults', JSON.stringify(calculatedResults));
              
              return;
            }
          } catch (e) {
            console.error('Error fetching settings from API:', e);
          }
        }
        
        // Fall back to user object if it has retirement settings
        if (user?.retirementSettings) {
          const settings = user.retirementSettings;
          const projectedSavings = calculateRetirementFutureValue(settings);
          
          setRetirementData({
            projectedSavings,
            loading: false
          });
          
          localStorage.setItem('retirementSettings', JSON.stringify(settings));
          
          const calculatedResults = {
            projectedSavings,
            dataPoints: generateRetirementDataPoints(settings)
          };
          localStorage.setItem('retirementResults', JSON.stringify(calculatedResults));
          
          return;
        }
        
        // Fall back to saved local results
        const savedResultsStr = localStorage.getItem('retirementResults');
        if (savedResultsStr) {
          try {
            const savedResults = JSON.parse(savedResultsStr);
            setRetirementData({
              projectedSavings: savedResults.projectedSavings,
              loading: false
            });
            return;
          } catch (e) {
            // If parse error, use defaults
          }
        }
        
        // Default to 0 if no data found
        setRetirementData({
          projectedSavings: 0,
          loading: false
        });
          
      } catch (error) {
        console.error('Error in retirement data fetching:', error);
        setRetirementData({
          projectedSavings: 0,
          loading: false
        });
      }
    };
    
    // Fetch investment data
    const fetchInvestmentData = async () => {
      try {
        // Try API first
        const { user: userApi } = await import('../services/api');
        const response = await userApi.getInvestmentAccounts();
        
        if (response?.data?.success) {
          const accounts = response.data.investmentAccounts || [];
          const total = accounts.reduce((sum, account) => sum + Number(account.currentValue), 0);
          
          setInvestmentData({
            portfolioValue: total,
            loading: false,
            accounts
          });
          return;
        }
      } catch (error) {
        console.error('Error fetching from API:', error);
        
        // Fall back to localStorage
        try {
          const savedAccountsStr = localStorage.getItem('investmentAccounts');
          if (savedAccountsStr) {
            const savedAccounts = JSON.parse(savedAccountsStr);
            const total = savedAccounts.reduce((sum, account) => sum + Number(account.currentValue), 0);
            
            setInvestmentData({
              portfolioValue: total,
              loading: false,
              accounts: savedAccounts
            });
            return;
          }
        } catch (e) {
          console.error('Error parsing saved investment accounts:', e);
        }
      }
      
      // Default to empty state
      setInvestmentData({
        portfolioValue: 0,
        loading: false,
        accounts: []
      });
    };
    
    fetchRetirementData();
    fetchInvestmentData();
  }, [isAuthenticated, user, calculateRetirementFutureValue, generateRetirementDataPoints]);

  return (
    <div className="dashboard">
      <h2>Your Financial Dashboard</h2>
      
      <p>Welcome back, {user?.firstName || user?.username}! Your financial journey continues here.</p>
      
      <div className="dashboard-overview">
        <div className="dashboard-metrics">
          <Link to="/calculators/retirement" className="metric-card">
            <h3>Retirement</h3>
            {retirementData.loading ? (
              <p>Loading savings projection...</p>
            ) : (
              <p>Projected savings: ${retirementData.projectedSavings.toLocaleString()}</p>
            )}
          </Link>
          
          <Link to="/calculators/housing" className="metric-card">
            <h3>Housing</h3>
            {housingLoading ? (
              <p>Loading affordability data...</p>
            ) : (
              <p>Affordability threshold: ${housingData.affordableHomePrice.toLocaleString()}</p>
            )}
          </Link>
          
          <Link to="/calculators/investments" className="metric-card">
            <h3>Investments</h3>
            {investmentData.loading ? (
              <p>Loading portfolio data...</p>
            ) : (
              <p>Current portfolio: ${investmentData.portfolioValue.toLocaleString()}</p>
            )}
          </Link>
        </div>

        <div className="dashboard-charts-container">
          <div className="chart-row">
            <div className="chart-section full-width">
              <div className="chart-header">
                <h3>Investment Portfolio Projections</h3>
                <div className="chart-controls">
                  <div className="return-rate-control">
                    <label>Return Rate: <span>{investmentData.returnRate || 7}%</span></label>
                    <div className="slider-container">
                      <input
                        type="range"
                        min="1"
                        max="15"
                        value={investmentData.returnRate || 7}
                        onChange={(e) => setInvestmentData(prev => ({
                          ...prev,
                          returnRate: parseInt(e.target.value)
                        }))}
                        className="return-rate-slider"
                      />
                      <div className="slider-markers">
                        <span 
                          className="slider-marker conservative" 
                          style={{left: '40%'}}
                          title="Conservative Estimate"
                        >
                          7%
                        </span>
                        <span 
                          className="slider-marker historical" 
                          style={{left: '60%'}}
                          title="Historical Average"
                        >
                          10%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {investmentData.loading ? (
                <div className="chart-loading">
                  <p>Loading investment data...</p>
                </div>
              ) : (
                <PortfolioChart 
                  accounts={investmentData.accounts} 
                  projectionMonths={240}
                  returnRate={investmentData.returnRate || 7}
                />
              )}
              <div className="chart-legend">
                <div className="legend-item">
                  <span className="line-style dashed"></span>
                  <span>Without Investment (Monthly Contributions)</span>
                </div>
                <div className="legend-item">
                  <span className="line-style solid"></span>
                  <span>With Investment Returns</span>
                </div>
              </div>
            </div>
          </div>

          <div className="chart-row">
            <div className="chart-section full-width">
              <div className="chart-header">
                <h3>Retirement Savings Projections</h3>
                <div className="chart-controls">
                  <div className="return-rate-control">
                    <label>Return Rate: <span>{retirementData.annualInterestRate || 7}%</span></label>
                    <div className="slider-container">
                      <input
                        type="range"
                        min="1"
                        max="15"
                        value={retirementData.annualInterestRate || 7}
                        onChange={(e) => setRetirementData(prev => ({
                          ...prev,
                          annualInterestRate: parseInt(e.target.value)
                        }))}
                        className="return-rate-slider"
                      />
                      <div className="slider-markers">
                        <span 
                          className="slider-marker conservative" 
                          style={{left: '40%'}}
                          title="Conservative Estimate"
                        >
                          7%
                        </span>
                        <span 
                          className="slider-marker historical" 
                          style={{left: '60%'}}
                          title="Historical Average"
                        >
                          10%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {retirementData.loading ? (
                <div className="chart-loading">
                  <p>Loading retirement data...</p>
                </div>
              ) : (
                <RetirementChart 
                  retirementSettings={{
                    initialAmount: user?.retirementSettings?.initialAmount || 10000,
                    monthlyContribution: user?.retirementSettings?.monthlyContribution || 500,
                    annualInterestRate: retirementData.annualInterestRate || 7,
                    years: user?.retirementSettings?.years || 30
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
