import { useState, useEffect } from 'react';

export const useHousingSettings = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        setLoading(true);
        setError(null);

        // Check if user is authenticated
        const token = localStorage.getItem('token');

        if (token) {
          try {
            // Import the API module
            const { user: userApi } = await import('../services/api');

            // Direct API call to get the latest settings from the database
            const response = await userApi.getHousingSettings();

            if (response && response.data && response.data.success) {
              // Use settings from the database
              const dbSettings = response.data.housingSettings;
              setSettings(dbSettings);

              // Update local storage with these settings
              localStorage.setItem('housingSettings', JSON.stringify(dbSettings));
              return;
            }
          } catch (e) {
            console.error('Error fetching settings from database:', e);
            setError('Could not load settings from your account');
            // If API call fails, try from localStorage
          }
        }

        // Fallback to localStorage if no user settings found
        const savedSettingsStr = localStorage.getItem('housingSettings');
        if (savedSettingsStr) {
          try {
            const savedSettings = JSON.parse(savedSettingsStr);
            setSettings(savedSettings);
          } catch (e) {
            console.error('Error parsing saved housing settings:', e);
            setError('Error loading saved settings');
          }
        } else {
          // Use default settings if nothing is saved
          const defaultSettings = {
            annualIncome: 75000,
            downPayment: 40000,
            interestRate: 4.5,
            loanTerm: 30,
            monthlyDebt: 500,
            propertyTaxRate: 1.2,
            homeInsurance: 100,
            hoa: 0
          };
          
          // Try to merge with any partial settings from localStorage
          let mergedSettings = { ...defaultSettings };
          try {
            const localSettings = localStorage.getItem('housingSettings');
            if (localSettings) {
              const parsedSettings = JSON.parse(localSettings);
              // Only use values that are explicitly set (including 0)
              Object.keys(parsedSettings).forEach(key => {
                if (parsedSettings[key] !== null && parsedSettings[key] !== undefined) {
                  mergedSettings[key] = parsedSettings[key];
                }
              });
            }
          } catch (e) {
            console.error('Error parsing local settings:', e);
          }
          
          setSettings(mergedSettings);
        }
      } catch (err) {
        console.error('Error in useHousingSettings:', err);
        setError('Error loading housing settings');
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  return { settings, loading, error };
};
