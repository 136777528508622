import React, { useState, useEffect, useRef, useCallback } from 'react';

// Account type definitions
const ACCOUNT_TYPES = {
  BROKERAGE: {
    name: 'Brokerage Account',
    defaultRate: 7,
    description: 'Standard investment account with no tax advantages, but more flexibility.',
    icon: '📈'
  },
  ROTH_IRA: {
    name: 'Roth IRA',
    defaultRate: 7,
    description: 'Tax-free growth for retirement, with contribution limits.',
    icon: '🛡️'
  },
  TRADITIONAL_401K: {
    name: '401(k)',
    defaultRate: 7,
    description: 'Employer-sponsored retirement plan with tax advantages.',
    icon: '💼'
  },
  HYSA: {
    name: 'High-Yield Savings',
    defaultRate: 3.5,
    description: 'FDIC-insured savings account with better interest rates.',
    icon: '🏦'
  },
  SAVINGS: {
    name: 'Savings Account',
    defaultRate: 0.5,
    description: 'Standard bank savings account for emergency funds.',
    icon: '💰'
  },
  CUSTOM: {
    name: 'Custom Account',
    defaultRate: 5,
    description: 'Create a custom investment account with your parameters.',
    icon: '✨'
  }
};

// Color mapping for different account types
const ACCOUNT_COLORS = {
  'BROKERAGE': '#4a90e2',
  'ROTH_IRA': '#f5a623',
  'TRADITIONAL_401K': '#9013fe',
  'HYSA': '#50e3c2',
  'SAVINGS': '#7ed321',
  'CUSTOM': '#d0021b'
};

// Helper function to get account color
const getAccountColor = (account) => {
  return ACCOUNT_COLORS[account.type] || ACCOUNT_COLORS['CUSTOM'];
};

const InvestmentTracker = () => {
  const [accounts, setAccounts] = useState([]);
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [showRemoveMenu, setShowRemoveMenu] = useState(false);
  const [customAccountName, setCustomAccountName] = useState('');
  const [totalValue, setTotalValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [expandedAccounts, setExpandedAccounts] = useState({});
  const [accountToRemove, setAccountToRemove] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const containerRef = useRef(null);
  const [columnsPerRow, setColumnsPerRow] = useState(1);

  // Load saved accounts
  useEffect(() => {
    const loadSavedAccounts = async () => {
      try {
        const { user } = await import('../../services/api');
        const response = await user.getInvestmentAccounts();
        
        if (response.data.success) {
          const savedAccounts = response.data.investmentAccounts;
          const parsedAccounts = savedAccounts.map(account => ({
            ...account,
            currentValue: Number(account.currentValue) || 0,
            annualReturn: Number(account.annualReturn) || 7,
            monthlyContribution: Number(account.monthlyContribution) || 0
          }));
          
          setAccounts(parsedAccounts);
          setTotalValue(parsedAccounts.reduce((sum, account) => sum + account.currentValue, 0));
        }
      } catch (err) {
        console.error('Error loading investment accounts:', err);
        try {
          const savedAccountsStr = localStorage.getItem('investmentAccounts');
          if (savedAccountsStr) {
            const savedAccounts = JSON.parse(savedAccountsStr);
            setAccounts(savedAccounts);
            setTotalValue(savedAccounts.reduce((sum, account) => sum + account.currentValue, 0));
          }
        } catch (localErr) {
          console.error('Error loading from localStorage:', localErr);
        }
      } finally {
        setLoading(false);
      }
    };
    
    loadSavedAccounts();
  }, []);

  // Save accounts when they change
  useEffect(() => {
    const saveAccounts = async () => {
      if (loading || accounts.length === 0) return;
      
      try {
        const { user } = await import('../../services/api');
        await user.saveInvestmentAccounts(accounts);
        
        setTotalValue(accounts.reduce((sum, account) => sum + account.currentValue, 0));
        localStorage.setItem('investmentAccounts', JSON.stringify(accounts));
      } catch (err) {
        console.error('Error saving investment accounts:', err);
      }
    };
    
    saveAccounts();
  }, [accounts, loading]);

  // Handle responsive layout
  useEffect(() => {
    const calculateColumns = () => {
      const containerEl = containerRef.current;
      if (!containerEl) return;

      const defaultCardWidth = 380;
      const defaultGap = 20;

      try {
        const firstCard = containerEl.querySelector('.investment-account-card');
        const cardWidth = firstCard ? firstCard.offsetWidth : defaultCardWidth;
        const computedStyle = window.getComputedStyle(containerEl);
        const gap = parseInt(computedStyle.gap) || defaultGap;

        setColumnsPerRow(Math.max(1, Math.floor((containerEl.offsetWidth + gap) / (cardWidth + gap))));
      } catch (err) {
        setColumnsPerRow(Math.max(1, Math.floor(containerEl.offsetWidth / defaultCardWidth)));
      }
    };

    const updateLayout = () => {
      if (containerRef.current) {
        calculateColumns();
      }
    };

    updateLayout();
    window.addEventListener('resize', updateLayout);

    // Use ResizeObserver if available
    const resizeObserver = window.ResizeObserver 
      ? new ResizeObserver(updateLayout)
      : null;
      
    if (resizeObserver && containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      window.removeEventListener('resize', updateLayout);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [accounts.length]);

  // Get row accounts for layout management
  const calculateRowAccounts = useCallback((clickedIndex) => {
    const rowIndex = Math.floor(clickedIndex / columnsPerRow);
    const rowStart = rowIndex * columnsPerRow;
    const rowEnd = Math.min(rowStart + columnsPerRow, accounts.length);
    
    return accounts
      .slice(rowStart, rowEnd)
      .map(account => account.id);
  }, [accounts, columnsPerRow]);

  // Toggle expansion of accounts in the same row
  const toggleExpansion = useCallback((accountId) => {
    const clickedIndex = accounts.findIndex(account => account.id === accountId);
    if (clickedIndex === -1) return;

    const rowAccounts = calculateRowAccounts(clickedIndex);
    const isCurrentlyExpanded = expandedAccounts[accountId];
    
    setExpandedAccounts(prev => {
      const newState = { ...prev };
      rowAccounts.forEach(id => {
        newState[id] = !isCurrentlyExpanded;
      });
      return newState;
    });
  }, [accounts, expandedAccounts, calculateRowAccounts]);

  const addAccount = (accountType, customName = '') => {
    const accountTemplate = ACCOUNT_TYPES[accountType];
    
    const newAccount = {
      id: `${accountType}-${Date.now().toString()}`,
      type: accountType,
      name: accountType === 'CUSTOM' ? customName : accountTemplate.name,
      icon: accountTemplate.icon,
      currentValue: 0,
      annualReturn: accountTemplate.defaultRate,
      monthlyContribution: 0,
      dateAdded: new Date().toISOString()
    };
    
    setAccounts([...accounts, newAccount]);
    setShowAddMenu(false);
    setCustomAccountName('');
  };

  const initiateRemoveAccount = (accountId) => {
    setAccountToRemove(accountId);
    setShowConfirmDialog(true);
  };

  const removeAccount = () => {
    if (accountToRemove) {
      setAccounts(accounts.filter(account => account.id !== accountToRemove));
      setShowRemoveMenu(false);
      setShowConfirmDialog(false);
      setAccountToRemove(null);
    }
  };

  const cancelRemove = () => {
    setShowConfirmDialog(false);
    setAccountToRemove(null);
  };

  const updateAccount = (accountId, field, value) => {
    setAccounts(accounts.map(account => {
      if (account.id === accountId) {
        return { ...account, [field]: value };
      }
      return account;
    }));
  };

  // Calculate future value with memoization
  const projectFutureValue = useCallback((account, years) => {
    const monthlyRate = (account.annualReturn / 100) / 12;
    const months = years * 12;
    let futureValue = account.currentValue;
    
    for (let i = 0; i < months; i++) {
      futureValue = futureValue * (1 + monthlyRate) + account.monthlyContribution;
    }
    
    return Math.round(futureValue);
  }, []);

  const renderAddAccountMenu = () => {
    return (
      <div className="add-account-menu">
        <h4>Add Investment Account</h4>
        <div className="account-type-grid">
          {Object.entries(ACCOUNT_TYPES).map(([key, type]) => (
            key !== 'CUSTOM' ? (
              <div 
                key={key} 
                className="account-type-card"
                onClick={() => addAccount(key)}
              >
                <div className="account-icon">{type.icon}</div>
                <div className="account-name">{type.name}</div>
                <div className="account-description">{type.description}</div>
              </div>
            ) : null
          ))}
          
          <div className="account-type-card custom">
            <div className="account-icon">{ACCOUNT_TYPES.CUSTOM.icon}</div>
            <div className="account-name">{ACCOUNT_TYPES.CUSTOM.name}</div>
            <input 
              type="text" 
              placeholder="Account Name" 
              value={customAccountName} 
              onChange={(e) => setCustomAccountName(e.target.value)}
              className="custom-account-input"
            />
            <button 
              className="custom-account-button"
              onClick={() => {
                if (customAccountName.trim()) {
                  addAccount('CUSTOM', customAccountName.trim());
                }
              }}
              disabled={!customAccountName.trim()}
            >
              Add Custom Account
            </button>
          </div>
        </div>
        
        <button 
          className="close-menu-button"
          onClick={() => setShowAddMenu(false)}
        >
          Cancel
        </button>
      </div>
    );
  };
  
  // Define NumericInput component outside useCallback to avoid dependency
  const NumericInput = ({ label, value, onChange, min = null }) => {
    const [localValue, setLocalValue] = useState(value === 0 ? '' : value.toString());

    const handleChange = (e) => {
      const inputValue = e.target.value;
      if (inputValue === '' || inputValue === '-') {
        setLocalValue(inputValue);
      } else {
        // Allow only valid number inputs
        const numericRegex = /^-?\d*\.?\d*$/;
        if (numericRegex.test(inputValue)) {
          setLocalValue(inputValue);
        }
      }
    };

    const handleBlur = () => {
      let finalValue = localValue === '' ? 0 : parseFloat(localValue);
      if (min !== null && finalValue < min) {
        finalValue = min;
      }
      setLocalValue(finalValue.toString());
      onChange(finalValue);
    };

    useEffect(() => {
      setLocalValue(value === 0 ? '' : value.toString());
    }, [value]);

    return (
      <div className="account-field">
        <label>{label}</label>
        <input 
          type="text"
          inputMode="decimal"
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
              e.preventDefault();
            }
          }}
        />
      </div>
    );
  };

  const renderAccountCard = useCallback((account) => {
    const accountColor = getAccountColor(account);
    const isExpanded = expandedAccounts[account.id];
    
    return (
      <div 
        className={`investment-account-card ${isExpanded ? 'expanded' : 'collapsed'}`}
        key={account.id} 
        id={`account-${account.id}`}
        data-type={account.type}
        style={{ 
          borderTop: `4px solid ${accountColor}`,
          maxHeight: isExpanded ? '500px' : '72px',
          transitionDuration: '0.15s'
        }}
      >
        <div 
          className="account-header"
          onClick={() => toggleExpansion(account.id)}
        >
          <span className="account-icon">{account.icon}</span>
          <h4 className="account-title">{account.name}</h4>
          <div className="account-value-summary">${account.currentValue.toLocaleString()}</div>
          <span className="expand-toggle">{isExpanded ? '▲' : '▼'}</span>
        </div>
        
        {isExpanded && (
          <>
            <div className="account-fields">
              <NumericInput 
                label="Current Value ($)" 
                value={account.currentValue} 
                onChange={(value) => updateAccount(account.id, 'currentValue', value)}
                min="0"
              />
              
              <NumericInput 
                label="Monthly Contribution ($)" 
                value={account.monthlyContribution} 
                onChange={(value) => updateAccount(account.id, 'monthlyContribution', value)}
                min="0"
              />
              
              <NumericInput 
                label="Expected Annual Return (%)" 
                value={account.annualReturn} 
                onChange={(value) => updateAccount(account.id, 'annualReturn', value)}
              />
            </div>
            
            <div className="account-projections">
              {[5, 10, 20].map(years => (
                <div className="projection-item" key={years}>
                  <span>{years} Year: </span>
                  <span className="projection-value">
                    ${projectFutureValue(account, years).toLocaleString()}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }, [expandedAccounts, toggleExpansion, updateAccount, projectFutureValue]);

  if (loading) {
    return <div className="loading">Loading your investment accounts...</div>;
  }

  return (
    <div className="investment-tracker">
      <h2>Investment Portfolio Tracker</h2>
      {accounts.length > 0 ? (
        <p>Track and project your investments across different account types.</p>
      ) : (
        <p>Add your investment accounts to track your portfolio and project future growth.</p>
      )}
      
      {accounts.length > 0 && (
        <div className="portfolio-summary">
          <div className="summary-total">
            <h3>Total Portfolio Value</h3>
            <div className="total-value">${totalValue.toLocaleString()}</div>
          </div>
          
          <div className="summary-allocation">
            <h3>Asset Allocation</h3>
            <div className="allocation-chart">
              {accounts.map(account => {
                const percentage = (account.currentValue / totalValue) * 100;
                return (
                  <div 
                    key={account.id}
                    className="allocation-bar" 
                    style={{ 
                      width: `${percentage}%`,
                      backgroundColor: getAccountColor(account)
                    }}
                    title={`${account.name}: $${account.currentValue.toLocaleString()} (${percentage.toFixed(1)}%)`}
                  />
                );
              })}
            </div>
            <div className="allocation-legend">
              {accounts.map(account => {
                const percentage = (account.currentValue / totalValue) * 100;
                return (
                  <div key={account.id} className="legend-item">
                    <div 
                      className="legend-color" 
                      style={{ backgroundColor: getAccountColor(account) }}
                    ></div>
                    <div className="legend-label">{account.name}</div>
                    <div className="legend-value">{percentage.toFixed(1)}%</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      
      <div className="investment-accounts" ref={containerRef}>
        {accounts.map(account => renderAccountCard(account))}
      </div>
      
      {showAddMenu ? (
        renderAddAccountMenu()
      ) : showRemoveMenu ? (
        <div className="remove-account-menu">
          <h4>Remove Investment Account</h4>
          <div className="account-selection-grid">
            {accounts.map(account => (
              <div 
                key={account.id} 
                className="account-selection-card"
                onClick={() => initiateRemoveAccount(account.id)}
              >
                <div className="account-icon">{account.icon}</div>
                <div className="account-name">{account.name}</div>
                <div className="account-value">${account.currentValue.toLocaleString()}</div>
              </div>
            ))}
          </div>
          
          <button 
            className="close-menu-button"
            onClick={() => setShowRemoveMenu(false)}
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="account-action-buttons">
          <button 
            className="minimal-action-button"
            onClick={() => setShowAddMenu(true)}
          >
            Add Investment Account
          </button>
          {accounts.length > 0 && (
            <button 
              className="minimal-action-button"
              onClick={() => setShowRemoveMenu(true)}
            >
              Remove Investment Account
            </button>
          )}
        </div>
      )}

      {showConfirmDialog && (
        <dialog open className="confirmation-dialog">
          <h3>Remove Investment Account</h3>
          <p>Are you sure you want to remove this investment account?</p>
          <div className="confirmation-dialog-buttons">
            <button className="cancel-button" onClick={cancelRemove}>Cancel</button>
            <button className="confirm-button" onClick={removeAccount}>Remove</button>
          </div>
        </dialog>
      )}
    </div>
  );
};

export default InvestmentTracker;
