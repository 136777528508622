import React, { useState } from 'react';

const BudgetInput = ({ timelineType, onSubmit, onCancel }) => {
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const budgetAmount = parseFloat(amount);
    if (!amount || isNaN(budgetAmount) || budgetAmount <= 0) {
      setError('Please enter a valid amount');
      return;
    }

    try {
      onSubmit(budgetAmount);
    } catch (error) {
      setError('Failed to set budget. Please try again.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content budget-input">
        <h2>Set Your {timelineType.charAt(0).toUpperCase() + timelineType.slice(1)} Budget</h2>
        <p className="budget-input-description">
          How much would you like to budget for this {timelineType} period?
        </p>
        <form onSubmit={handleSubmit}>
          <div className="amount-input-wrapper">
            <span className="currency-symbol">$</span>
            <input
              type="number"
              value={amount}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || parseFloat(value) >= 0) {
                  setAmount(value);
                  setError('');
                }
              }}
              onBlur={(e) => {
                const value = e.target.value;
                if (value) {
                  setAmount(parseFloat(value).toFixed(2));
                }
              }}
              placeholder="0.00"
              step="0.01"
              min="0"
              autoFocus
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="modal-actions">
            <button type="submit" className="primary-button">
              Set Budget
            </button>
            <button type="button" className="secondary-button" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BudgetInput;
