import React, { useMemo } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart
} from 'recharts';

const PortfolioChart = ({ accounts = [], projectionMonths = 240, returnRate = 7 }) => {
    // Generate projection data for a single account
  const generateProjectionData = (account) => {
    // Validate and convert inputs
    const currentValue = Number(account.currentValue) || 0;
    const monthlyContribution = Number(account.monthlyContribution) || 0;
    const monthlyRate = (returnRate / 100) / 12;
    const years = Math.ceil(projectionMonths / 12);

    const data = [];
    let withoutInvestment = currentValue;
    let withInvestment = currentValue;
    let currentMonth = 0;
    
    // Add starting point
    data.push({
      year: 0,
      month: currentMonth,
      withoutInvestment: currentValue,
      withInvestment: currentValue,
      isHalfYear: false
    });

    // Generate projections year by year
    for (let year = 0; year < years; year++) {
      // Calculate month by month but only save half-year and year points
      for (let month = 1; month <= 12; month++) {
        currentMonth++;
        if (currentMonth > projectionMonths) break;

        // Without investment - just add monthly contribution
        withoutInvestment += monthlyContribution;

        // With investment - compound the entire existing balance
        withInvestment = withInvestment * (1 + monthlyRate) + monthlyContribution;

        // Save data points at half year and year marks
        if (month === 6 || month === 12) {
          data.push({
            year: year + (month === 6 ? 0.5 : 1),
            month: currentMonth,
            withoutInvestment: Math.round(withoutInvestment),
            withInvestment: Math.round(withInvestment),
            isHalfYear: month === 6
          });
        }
      }
    }

    return data;
  };

  // Create combined dataset with all accounts - memoized to prevent recalculation
  const combinedData = useMemo(() => {
    if (!accounts || accounts.length === 0) {
      return [];
    }

    return accounts.reduce((acc, account) => {
      const projection = generateProjectionData(account);
      projection.forEach((point, idx) => {
        if (!acc[idx]) {
          acc[idx] = {
            year: point.year,
            month: point.month,
            isHalfYear: point.isHalfYear,
            totalWithoutInvestment: 0,
            totalWithInvestment: 0,
            ...accounts.reduce((nameAcc, a) => ({
              ...nameAcc,
              [`${a.name}WithoutInvestment`]: 0,
              [`${a.name}WithInvestment`]: 0
            }), {})
          };
        }
        acc[idx][`${account.name}WithoutInvestment`] = point.withoutInvestment;
        acc[idx][`${account.name}WithInvestment`] = point.withInvestment;
        acc[idx].totalWithoutInvestment += point.withoutInvestment;
        acc[idx].totalWithInvestment += point.withInvestment;
      });
      return acc;
    }, []);
  }, [accounts, projectionMonths, returnRate, generateProjectionData]);

  // Format functions
  const formatYAxis = (value) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `$${(value / 1000).toFixed(1)}K`;
    }
    return `$${value}`;
  };

  const formatXAxis = (value) => {
    if (value === 0) return '0';
    return value % 1 === 0 ? `${value}y` : '';
  };

  const formatTooltip = (value) => {
    if (value === null) return '-';
    return `$${value.toLocaleString()}`;
  };

  // Early return if no accounts
  if (!accounts || accounts.length === 0) {
    return (
      <div style={{ width: '100%', minHeight: 450, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', borderRadius: '12px', padding: '2rem' }}>
        <p>No investment accounts to display. Add accounts to see projections.</p>
      </div>
    );
  }

  const chartStyle = {
    width: '100%',
    minHeight: 450,
    padding: '1.5rem',
    background: 'white',
    borderRadius: '12px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div style={chartStyle}>
      <div style={{ width: '100%', height: 450 }}>
        <ResponsiveContainer>
          <ComposedChart data={combinedData} margin={{ top: 20, right: 35, left: 80, bottom: 25 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#e2e8f0" />
            <XAxis 
              dataKey="year" 
              tickFormatter={formatXAxis}
              ticks={Array.from({ length: 21 }, (_, i) => i)}
              label={{ 
                value: 'Time (Years)', 
                position: 'insideBottom', 
                offset: -15,
                style: { 
                  fill: '#64748b',
                  fontSize: 13 
                }
              }}
              stroke="#64748b"
              tick={(props) => {
                const { x, y, payload } = props;
                const isHalfYear = combinedData.find(d => d.year === payload.value)?.isHalfYear;
                return (
                  <g transform={`translate(${x},${y})`}>
                    <text
                      x={0}
                      y={0}
                      dy={16}
                      textAnchor="middle"
                      fill={isHalfYear ? "#94a3b8" : "#64748b"}
                      fontSize={13}
                      opacity={isHalfYear ? 0.6 : 1}
                    >
                      {formatXAxis(payload.value)}
                    </text>
                  </g>
                );
              }}
            />
            <YAxis 
              tickFormatter={formatYAxis}
              label={{ 
                value: 'Portfolio Value', 
                angle: -90, 
                position: 'insideLeft', 
                offset: -60,
                style: { 
                  fill: '#64748b',
                  fontSize: 13
                }
              }}
              stroke="#64748b"
              tick={{ 
                fill: '#64748b',
                fontSize: 13
              }}
              width={75}
            />
            <Tooltip 
              formatter={formatTooltip}
              labelFormatter={(year) => `Year ${year}`}
              contentStyle={{
                backgroundColor: 'white',
                border: '1px solid #e2e8f0',
                borderRadius: '8px',
                padding: '12px 16px',
                fontSize: '14px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
              }}
            />
            
            {accounts.map((account, index) => {
              const color = account.color || `hsl(${(index * 137.5) % 360}, 70%, 50%)`;
              return (
                <React.Fragment key={account.id}>
                  {/* Dashed line - without investment */}
                  <Line
                    name={`${account.name} (Without Investment)`}
                    type="monotone"
                    dataKey={`${account.name}WithoutInvestment`}
                    stroke={color}
                    strokeDasharray="5 5"
                    strokeWidth={2}
                    dot={false}
                  />
                  {/* Solid line - with investment */}
                  <Line
                    name={`${account.name} (With Investment)`}
                    type="monotone"
                    dataKey={`${account.name}WithInvestment`}
                    stroke={color}
                    strokeWidth={2}
                    dot={(props) => {
                      if (!props || combinedData[props.index]?.isHalfYear) return null;
                      return (
                        <circle
                          cx={props.cx}
                          cy={props.cy}
                          r={4}
                          fill={color}
                          strokeWidth={1}
                        />
                      );
                    }}
                    activeDot={{ r: 6, strokeWidth: 0 }}
                  />
                </React.Fragment>
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PortfolioChart;
